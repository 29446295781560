<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 120px">
          <a-input
              class="input"
              v-model="queryParams.uid"
              placeholder="用户ID"
              allowClear
          />
        </div>

        <div class="one-item" style="width: 100px">
          <a-select
              class="input"
              v-model="queryParams.status"
              placeholder="状态"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in postStatusList" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>

        <div class="one-item" style="width: 200px">
          <a-input
              class="input"
              v-model="queryParams.keyword"
              placeholder="任务类型"
              allowClear
          />
        </div>
        <div class="one-item" style="padding-left: 10px;width:250px;">
          <range-date @change="handleDateChange" ref="createTime" style="width: 100%"></range-date>
        </div>

      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="images" slot-scope="text, record">
        <div v-if="text" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(text || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(text || '').split(',')"
          />
        </div>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('postStatus', 'color')">{{
            record.status | dictName("postStatus")
          }}
        </a-tag>
      </template>
      <template slot="clientType" slot-scope="text, record">
        <a-tag :color="record.clientType | dictName('clientType', 'color')">{{
            record.clientType | dictName("clientType")
          }}
        </a-tag>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";
import RangeDate from '@/components/datetime/RangeDate'
import {DICT} from "@/utils/dict";

export default {
  name: "UserScoreList",
  mixins: [ListMixin],
  components:{
    RangeDate
  },
  data() {
    return {
      listGetUrl: "userScore/listByAdmin",
      listMethod: "postJson",
      showLoading: false,
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    postStatusList() {
      return DICT['postStatus']
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        // {
        //   title: "头像",
        //   align: "center",
        //   width: 60,
        //   dataIndex: "userInfo.avatar",
        //   scopedSlots: {customRender: "images"},
        // },
        {
          title: "用户ID",
          width: 80,
          align: "center",
          dataIndex: "uid",
        },
        {
          title: "用户名",
          width: 150,
          align: "center",
          dataIndex: "userInfo.username",
        },
        {
          title: "任务编码",
          width: 200,
          align: "center",
          dataIndex: "taskCode",
        },
        {
          title: "任务名称",
          width: 150,
          align: "center",
          dataIndex: "taskName",
        },
        {
          title: "积分",
          width: 80,
          align: "center",
          dataIndex: "score",
        },
        {
          title: "备注说明",
          width: 300,
          align: "center",
          dataIndex: "remark",
        },
        {
          title: "状态",
          width: 80,
          align: "center",
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
        },
        {
          title: "时间",
          align: "center",
          width: 230,
          dataIndex: "createTime",
        },
        {
          title: "客户端",
          width: 80,
          align: "center",
          dataIndex: "clientType",
          scopedSlots: {customRender: "clientType"},
        },
        {
          // title: "操作",
          // align: "center",
          // dataIndex: "operation",
          // fixed: "right",
          // scopedSlots: {customRender: "operation"},
        },
      ];
    },
  },
  mounted() {
    this.search();
  },
  methods: {
    handleDateChange (value) {
      if (value) {
        this.queryParams.createTimeFrom = value[0]
        this.queryParams.createTimeTo = value[1]
      }
    },
    // editSuccess(operate) {
    //   this.search();
    //   if (!operate) {
    //     this.$message.success("状态配置成功");
    //     return;
    //   }
    //   if (operate == 1) {
    //     this.$message.success("审核认证成功");
    //   } else {
    //     this.$message.error("审核认证不成功");
    //   }
    // },
    //
    // auditUser(record) {
    //   // if (record.authFlag != 1) {
    //   //   // this.$message.success("该用户已经认证完成，无需再审核");
    //   //   return;
    //   // }
    //
    //   if (this.$helper.hasPermission('user:audit')) {
    //     this.$refs.userAuditModal.showModal(record);
    //   } else {
    //     this.$message.warn("对不起，您没有审核权限")
    //   }
    // },
    // handleAction(e, row) {
    //   switch (e.key) {
    //     case "auth":
    //       if (row.authFlag == 2) {
    //         this.$message.success("该用户已经认证完成，无需再审核");
    //         return;
    //       }
    //       // if (row.authFlag == 0) {
    //       //   this.$message.success("该用户资料未完善，不支持审核");
    //       //   return;
    //       // }
    //       this.$refs.userAuditModal.showModal(row);
    //       break;
    //     case "setting":
    //       this.$refs.settingModal.showModal(row);
    //       break;
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
